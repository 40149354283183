import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import mergeRefs from "react-merge-refs";
import os from "platform-detect/os.mjs";
import {
  H1,
  IntroLine,
  StylizedButton,
  NavigationIndicator,
} from "../styled/commons";
import { AiFillApple, AiFillWindows } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import scrollIndicator from "../assets/img/scroll-indicator.svg";
import rightPosture from "../assets/img/right-posture.svg";
import screen from "../assets/img/screen.svg";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;

  & > div {
    display: flex;
    flex: 1;

    & > div {
      margin: auto;
      width: 60%;

      @media (max-width: 768px) {
        text-align: center;

        button {
          margin: 2rem auto 0 auto;
        }
      }
    }
  }

  .text__side {
    width: 60%;
    padding-right: 7%;
    will-change: opacity;

    @media (max-width: 768px) {
      flex: 1;
      position: relative;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 25% 10% 0 10%;

      h1 {
        font-size: 3rem !important;
        line-height: 2.7rem !important;
        margin-top: 0 !important;
      }

      p {
        font-size: 1.3rem !important;
      }
    }
  }

  .second-item {
    position: relative;
    height: 100%;

    .image--content {
      touch-action: none;
      pointer-events: none;
      position: relative;
      /* border: 1px solid red; */
      height: 100%;
      width: 100%;
      display: flex;
      transform: translateY(4.5rem) translateX(-2rem);

      .contain {
        position: relative;
        margin: auto;

        width: 80%;
        height: 80%;
        /* border: 1px solid red; */

        transform: scale(2.4);
      }

      object {
        position: absolute;
        top: calc(50% - 25%);
        left: 0;
        font-size: 12px;
        object-fit: contain;
        height: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    height: 80vh;

    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .image--content {
      margin-top: 4rem;
    }

    .second-item {
      flex: 1;
      position: absolute;
      z-index: 0;
      top: 0;
      transform: scale(1.5);
    }
  }

  .ph_badges {
    transform: translate(-48px, 2rem) scale(0.8);

    display: flex;
    align-items: center;

    a:first-child {
      margin-right: 15px;
    }

    @media (max-width: 768px) {
      transform: translate(0, 0) scale(1);

      margin-top: 40px;

      flex-direction: column;
      align-items: center;
      justify-content: center;

      a:first-child {
        margin: 0 0 10px 0;
      }

      q img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

const Section1 = () => {
  const map = (value, x1, y1, x2, y2) =>
    ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
  const containerRef = useRef();
  const [showAnimation, setShowAnimation] = useState(true);
  const scrollIndicatorRef = useRef();
  const objectRef = useRef();
  const objectRef2 = useRef();
  const canDoMoveAnimation = useRef(true);
  const canDoMoveAnimationTimeout = useRef();

  useEffect(() => {
    window.addEventListener("scroll", fixedCheck);
    window.addEventListener("scroll", handleSpyLottieVisibility);

    containerRef.current.addEventListener("mouseenter", mouseEnter);
    containerRef.current.addEventListener("mousemove", mouseMove);
    containerRef.current.addEventListener("mouseleave", mouseLeave);

    return () => {
      window.removeEventListener("scroll", fixedCheck);
      window.removeEventListener("scroll", handleSpyLottieVisibility);
    };

    // eslint-disable-next-line
  }, []);

  const [ref] = useInView({
    threshold: 0,
  });

  const fixedCheck = () => {
    const scrollHeight = window.pageYOffset;

    const elem = containerRef.current;

    const secondHalfOffset =
      window.document.body.scrollHeight / 2 +
      window.document.body.scrollHeight / 8;

    if (scrollHeight <= secondHalfOffset) {
      const newOpacityValue = map(scrollHeight, 0, 700, 1, 0);

      if (newOpacityValue >= -1 && newOpacityValue <= 1) {
        elem.style.opacity = newOpacityValue;
        elem.classList.remove("shiftToCenter");
      }
    } else {
      elem.style.opacity = 1;
    }
  };

  const handleSpyLottieVisibility = () => {
    /**
     * Optimization logics to make lottie animation
     * invisible when not inview, so as to prevent
     * offscreen renders which will save us significant
     * resources which we need for other animations
     * on the page that are in view.
     */
    const scrollY = window.pageYOffset;
    // on mobile devices, make the threshold more longer..
    const topHideThreshold =
      window.screen.width <= 768
        ? window.innerHeight + window.innerHeight / 2
        : window.innerHeight;
    if (scrollY >= 0 && scrollY <= topHideThreshold) {
      setShowAnimation(true);
      // not part of the optimization.
      scrollIndicatorRef.current.classList.add("up");
      scrollIndicatorRef.current.classList.remove("down");
    } else {
      if (scrollY > document.body.scrollHeight - (1000 + window.innerHeight)) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
      // not part of the optimization.
      scrollIndicatorRef.current.classList.add("down");
      scrollIndicatorRef.current.classList.remove("up");
    }
  };

  const animateObject = (
    ref = [],
    {
      rotateX = 0,
      rotateY = 0,
      translateZ = 0,
      scale = 1,
      willChange = "",
      transition = "",
    },
  ) => {
    try {
      ref.forEach((_ref) => {
        _ref.current.style.willChange = willChange;
        _ref.current.style.transition = transition;
        _ref.current.style.transform = `rotateY(${rotateX}deg) rotateX(${rotateY}deg) scale(${scale}) translateZ(${translateZ})`;
      });
    } catch (e) {}
  };

  const mouseEnter = () => {
    canDoMoveAnimation.current = false;

    animateObject([objectRef, objectRef2], {
      willChange: "transform",
      transition: "all .5s ease",
      scale: 1.06,
      rotateX: 0,
      rotateY: 0,
      translateZ: 0,
    });

    if (canDoMoveAnimationTimeout.current)
      clearInterval(canDoMoveAnimationTimeout.current);

    canDoMoveAnimationTimeout.current = setTimeout(() => {
      canDoMoveAnimation.current = true;
    }, 500);
  };

  const mouseMove = ($event) => {
    if (!canDoMoveAnimation.current) return;

    const deltaX = (window.innerWidth / 2 - $event.pageX) / 25;
    const deltaY = (window.innerHeight / 2 - $event.clientY) / 25;

    animateObject([objectRef], {
      willChange: "",
      transition: "",
      scale: 1.06,
      rotateX: deltaX,
      rotateY: deltaY,
      translateZ: "10px",
    });

    animateObject([objectRef2], {
      willChange: "",
      transition: "",
      scale: 1.06,
      rotateX: deltaX,
      rotateY: deltaY,
      translateZ: "100px",
    });
  };

  const mouseLeave = () => {
    animateObject([objectRef, objectRef2], {
      willChange: "transform",
      transition: "all .5s ease",
      scale: 1,
      rotateX: 0,
      rotateY: 0,
      translateZ: 0,
    });

    canDoMoveAnimation.current = false;
  };

  const openDownloadLink = () => {
    const url = os.macos
      ? "https://usezap.sh"
      : "https://rebrand.ly/switch-win";

    window.open(url, "_self");
  };

  return (
    <Flex ref={mergeRefs([ref, containerRef])}>
      <div>
        <div className="text__side">
          <H1
            style={{
              marginBottom: "1.5rem",
              wordSpacing: "-2vw",
              fontSize: "75px",
              lineHeight: "75px",
            }}
          >
            Strive{" "}
            <span role="img" aria-label="emote">
              {os.macos || os.ios ? "🤌" : ""}
            </span>
            <br />
            to be lazy
          </H1>
          <IntroLine>
            Keyboard-driven commands
            <br /> to better navigate your{" "}
            {os.macos || os.ios ? "mac" : "Windows"} apps
          </IntroLine>
          <StylizedButton
            id="download"
            title="Download now"
            onClick={openDownloadLink}
          >
            <div className="logos">
              {os.macos ? <AiFillApple /> : <AiFillWindows />}
            </div>
            <span>Download for {os.macos || os.ios ? "macOS" : "Windows"}</span>
          </StylizedButton>

          <div className="ph_badges">
            <a
              href="https://www.producthunt.com/posts/switch-8?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-switch-8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=166945&theme=dark&period=daily"
                alt="Switch - Zap between your favorite apps lightning fast. | Product Hunt Embed"
                style={{ width: "250px", height: "54px" }}
              />
            </a>
            <a
              href="https://www.producthunt.com/posts/switch-for-macos?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-switch-for-macos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=281800&theme=dark&period=daily"
                alt="Switch for macOS - Keyboard-driven commands to navigate your mac apps faster. | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="second-item">
        {showAnimation && (
          <div className="image--content">
            <div className="contain">
              <object
                type="image/svg+xml"
                className="posture"
                data={rightPosture}
                ref={objectRef}
                style={{
                  width: "100%",
                  perspective: "1000px",
                  transformStyle: "preserve-3d",
                }}
              >
                Switch's MacOs right-hand posture
              </object>
              <object
                type="image/svg+xml"
                className="posture"
                data={screen}
                ref={objectRef2}
                style={{
                  width: "100%",
                  perspective: "1000px",
                  transformStyle: "preserve-3d",
                }}
              >
                Switch's MacOs right-hand posture
              </object>
            </div>
          </div>
        )}
      </div>
      <NavigationIndicator ref={scrollIndicatorRef} style={{ bottom: "10%" }}>
        <img src={scrollIndicator} alt="scroll indicator" />
      </NavigationIndicator>
    </Flex>
  );
};

export default Section1;
