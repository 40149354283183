import React from "react";
import styled from "styled-components";
import { gradientValues, growWidth } from "./commons";
import logo from "../assets/img/logo.svg";

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99999;
  margin-top: 2rem;

  @media (max-width: 768px) {
    position: absoulte;
    margin: 0;
  }
`;

export const HeaderLogo = styled.a`
  padding-left: 8.3vw;
  cursor: pointer;
  display: block;
  text-decoration: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
  padding-right: 8.3vw;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 0;
  }
`;

export const HeaderItem = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;

  color: white;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;

  margin: 2rem 1.5rem;

  cursor: pointer;

  span {
    position: relative;
    z-index: 1;
  }

  & svg {
    margin-right: 0.4rem;
  }

  &:hover::before {
    position: absolute;
    bottom: -25%;
    left: -20%;

    content: "";
    height: 100%;
    border-radius: 50rem;
    background: ${gradientValues};
    z-index: 0;

    animation-name: ${growWidth};
    animation-duration: 0.3s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }

  @media (max-width: 768px) {
    margin: 2rem 0.8rem;
    cursor: pointer;
  }
`;

const Header = ({ handleToggleDemoVideoModal }) => {
  return (
    <HeaderContainer role="header">
      <HeaderLogo href="/">
        <img src={logo} alt="logo" width="45" height="45" />
      </HeaderLogo>
      <NavLinks role="nav-links">
        <HeaderItem role="nav-link" onClick={handleToggleDemoVideoModal}>
          <span>
            <span aria-label="emoji" role="img">
              ▶️&nbsp;
            </span>
            Watch Video
          </span>
        </HeaderItem>
        <HeaderItem
          role="nav-link"
          href="https://discord.com/invite/uXqdSM24qH"
        >
          <span>
            <span aria-label="emoji" role="img">
              📣&nbsp;
            </span>
            Discord
          </span>
        </HeaderItem>
        <HeaderItem
          role="nav-link"
          href="https://github.com/ahkohd/switch-desktop"
        >
          <span>
            <span aria-label="emoji" role="img">
              ⭐&nbsp;
            </span>
            Star on Github
          </span>
        </HeaderItem>
        <HeaderItem
          role="nav-link"
          href="https://github.com/ahkohd/switch-desktop/wiki/FAQs"
        >
          <span>
            <span aria-label="emoji" role="img">
              💬&nbsp;
            </span>
            FAQs
          </span>
        </HeaderItem>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
