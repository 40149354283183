import { keyframes, css } from "styled-components";
import styled from "styled-components";

export const gradientValues = `linear-gradient(157.54deg, #f55f44 14.66%, #6c63fd 85.29%)`;

export const animatedGradbg = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const arrowAnim = keyframes`
  0, 100% {
    transform: translateX(0) scale(.6);
  }

  48% {
    transform: translateX(-100%) scale(.6);
    opacity: 1;
  }
  49% {
    transform: translateX(-100%) scale(.6);
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateX(100%) scale(.6);
  }
  51%{
    opacity: 1;
  }
`;

export const arrowAnim2 = keyframes`
  0, 100% {
    transform: translateY(0);
    opacity: 0;
  }

  48% {
    transform: translateY(-130%);
    opacity: 1;
  }
  49% {
    transform: translateY(-130%);
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateY(130%);
  }
  51%{
    opacity: 1;
  }
`;

export const growWidth = keyframes`
    from {
        width: 0;
    }

    to {
        width: 105%;
    }
`;

export const H1 = styled.h1`
  font-family: "Space Mono", sans-serif;
  font-size: 2.25rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  white-space: nowrap;
  line-height: 2.25rem;
  font-weight: 800;
  // text-transform: uppercase;
`;

export const TagLine = styled.p`
  font-size: 1.2rem;
  margin: 0;
  cursor: pointer;
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

export const IntroLine = styled(TagLine)`
  font-size: 1.5rem;
  color: white;
  min-height: 111px;
`;

export const StylizedButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  cursor: pointer;

  background: transparent;
  border: none;

  font-family: "Space Mono", sans-serif;
  font-size: 1.8rem;
  font-weight: 800;

  margin-top: 3rem;
  transition: all ease 0.3s;
  transform: translateX(-0.9rem);

  svg {
    color: white;
    font-size: 2rem;
  }

  .logos {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    margin-right: 0rem;

    svg:first-child {
      margin-right: 10px;
    }
  }

  & > span {
    white-space: nowrap;
    background: ${gradientValues};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
    animation: ${animatedGradbg} 2s ease infinite;
  }

  &:hover {
    transform: perspective(100rem) translateZ(2rem);
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
    transform: translateX(0);

    svg:first-child {
      margin-right: 5px;
    }
  }

  @media (max-width: 425px) {
    svg {
      font-size: 1.5rem;
      margin-bottom: 0.2rem;
    }
  }
`;

export const H2 = styled(H1)`
  font-size: 1.65rem;
  position: relative;
  line-height: 2.2rem;
  white-space: normal;
  font-weight: 600;

  ${(stylized) =>
    stylized &&
    css`
      &::before {
        position: absolute;
        bottom: -0.7rem;
        left: 0;

        content: "";
        height: 0.4rem;
        width: 1.5rem;
        border-radius: 50rem;
        background: ${gradientValues};
        background-size: 150% 150%;
        animation: ${animatedGradbg} 4s ease infinite;

        @media (max-width: 768px) {
          left: calc(50% - 1.5rem / 2);
        }
      }
    `}
`;

export const H3 = styled.h3`
  font-family: "Space Mono", sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  position: relative;
  line-height: 2.2rem;
  white-space: normal;
  // text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
`;

export const NavigationIndicator = styled.button`
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  left: calc(50% - 3rem / 2);
  color: white;
  z-index: 99999;
  background: transparent;
  /* border: 1px solid rgba(255, 255, 255, 0.05); */

  @media (max-width: 768px) {
    display: flex;
  }

  img {
    margin: auto;
    height: 30px;
    width: auto;
    animation: ${arrowAnim2} 1s ease infinite;
  }

  &.up {
    transform: rotate(0deg);
  }

  &.down {
    transform: rotate(180deg);
    top: 0;
    bottom: unset;
  }
`;
