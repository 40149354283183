import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import loader from "../assets/img/loader.svg";

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  background: black;
  position: fixed;

  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  top: 0;
  left: 0;
  z-index: 999999;

  .cover__content {
    margin: auto;
  }
`;

const Cover = ({ onHideCover = () => {} }) => {
  const coverRef = useRef();
  const ref = useRef();

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    setTimeout(() => {
      document.body.style.overflowY = "scroll";
      coverRef.current.classList.add("animate__fadeOut");
      ref.current.classList.remove("animate__fadeInRight");
      ref.current.classList.add("animate__fadeOutLeft");

      setTimeout(() => {
        onHideCover();
      }, 1500 * 1.5);
    }, 2500 * 1.5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="cover animate__animated" ref={coverRef}>
      <div className="cover__content">
        <div className="animate__animated animate__fadeInRight" ref={ref}>
          <img
            src={loader}
            alt="preloader"
            aria-label="preloader"
            width="65"
            height="65"
          />
        </div>
      </div>
    </Container>
  );
};

export default Cover;
