import React from "react";
import styled from "styled-components";
import wave3 from "../assets/img/wave3.svg";
import moon from "../assets/img/moon.svg";
import chilling from "../assets/img/chilling.svg";
import { H2, TagLine } from "../styled/commons";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  height: 80vh;
  background: #221f52;
  z-index: 18;
  position: relative;
  /* transform: translateY(30vh); */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    bottom: -22.8rem;
    left: 0;
    background: url(${wave3}) no-repeat;
    height: 23rem;
    background-size: contain;
    background-position: center top;
  }
`;

const Absolute = styled.div`
  height: 120%;
  width: 90%;

  position: absolute;
  top: 30rem;
  left: 5%;

  display: grid;
  grid-template-columns: 2fr 1fr 0.5fr;
  grid-column-gap: 3rem;
  position: absolute;
  z-index: 3;

  transform: translateY(-10rem);

  .row {
    flex: 1;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 80%;
    left: 10%;
    top: 47%;

    .h2,
    p {
      text-align: center;
      width: 100% !important;
    }

    .moon-container {
      display: none;
    }

    .moon {
      margin-bottom: 3rem;
    }

    .chilling-container {
      max-height: 30px;
    }
  }
`;

const Section3 = () => {
  const [ref, inView] = useInView({
    // threshold: 0.6,
  });

  return (
    <Container>
      <Absolute ref={ref}>
        <div className="row chilling-container">
          <img
            src={chilling}
            alt="Focus on what matters"
            className={`animate__animated ${
              inView ? "animate__fadeInBottomLeft" : "not-visible"
            }`}
          />
        </div>
        <div className="row">
          <div
            className={`animate__animated ${
              inView ? "animate__fadeInUp" : "not-visible"
            }`}
          >
            <H2 stylized={true} className="h2">
              Focus on stuff that matters{" "}
              <span role="img" aria-label="focus">
                🎯
              </span>
            </H2>
            <TagLine style={{ marginBottom: "2rem", marginTop: "2rem" }}>
              With an intuitive ergonomic design, Switch app makes working on your
              computer simple and fun
            </TagLine>
          </div>
        </div>
        <div className="row moon-container">
          <img
            src={moon}
            className={`moon animate__animated ${
              inView ? "animate__zoomInRight" : "not-visible"
            }`}
            alt="moon"
            style={{ width: "7vw" }}
          />
        </div>
      </Absolute>
    </Container>
  );
};

export default Section3;
