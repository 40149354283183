import React, { useState } from "react";
import ModalVideo from 'react-modal-video';
import os from "platform-detect/os.mjs";
import Header from "./styled/Header";
import "../node_modules/animate.css/animate.min.css";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import Section6 from "./sections/Section6";
import Foot from "./sections/Foot";
import Cover from "./components/Cover";
import CookieCard, {
  hasAcceptedCookiePersmission,
} from "./components/CookieCard";

const App = () => {
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="App">
        <Header role="nav" aria-label="Navigation links" handleToggleDemoVideoModal={()=> setOpen((prev) => !prev)} />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Foot />
      {!isReady && <Cover onHideCover={() => setIsReady(true)} />}
      {isReady && !hasAcceptedCookiePersmission() && <CookieCard />}
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={os.macos || os.ios ? 'GQ-l3Vu4Hak' : 'cysVHi-pcxU'} onClose={() => setOpen(false)} />
    </div>
  );
};

export default App;
