import React from "react";
import styled from "styled-components";
import { arrowAnim, H3, TagLine } from "../styled/commons";
import scrollBlurOut from "../assets/img/scroll-blur-out.png";

const Container = styled.div`
  background: #08081a;
  border-radius: 2rem;
  padding: 2rem;
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  text-align: center;
  overflow: visible;

  &::after {
    content: " ";
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    box-sizing: border-box;
    left: calc(50% - 5rem / 2);
    top: calc((5rem / 2) * -1);
    background: url(${({ clientPicture }) => clientPicture}) no-repeat;
    background-size: cover;
    border: 0.6rem solid #08081a;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

const ExtendedH3 = styled(H3)`
  text-align: center;
  margin: 1rem 0 0 0;
  font-size: 1.25rem;
`;

export const TestimonialsContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: " ";
    position: absolute;
    left: -3rem;
    top: 0;
    height: 100%;
    width: 10rem;
    z-index: 888;
    background: url(${scrollBlurOut}) no-repeat;
    background-size: contain;
    background-position: center;
  }

  &::after {
    content: " ";
    position: absolute;
    right: -4rem;
    top: 0;
    height: 100%;
    width: 10rem;
    z-index: 888;
    background: url(${scrollBlurOut}) no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
  }

  .pane {
    height: 100%;
    width: 100%;
    padding: 2.5rem 0;
    overflow-x: scroll;
    overflow-y: visible;

    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  & .row {
    display: table;
    margin-top: -3rem;
    border-spacing: 3rem;
    border-collapse: separate;
    width: unset;
    align-items: stretch;

    & .testimonial--container {
      min-width: 30vh;
      display: table-cell;
    }

    & .testimonial--container:first-child {
      @media (max-width: 768px) {
        margin-left: 5%;
      }
    }
  }

  .testimonial_naviagtion_button--left,
  .testimonial_naviagtion_button--right {
    position: absolute;
    z-index: 99999;
  }

  .testimonial_naviagtion_button--left {
    left: calc(11% / 2);
    top: calc(50% - 3rem / 2);
  }

  .testimonial_naviagtion_button--right {
    right: calc(11% / 2);
    top: calc(50% - 3rem / 2);
  }
`;

export const TestimonialIcon = () => (
  <svg
    width="45"
    height="24"
    style={{ transform: `scale(.6)` }}
    viewBox="0 0 45 24"
    fill="none"
  >
    <path
      d="M12 3L3 12M3 12L12 21M3 12H41.5"
      stroke="url(#paint0_linear)"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="14.4074"
        y1="-7.28571"
        x2="41.8193"
        y2="4.94034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F55F44" />
        <stop offset="1" stopColor="#6C63FD" />
      </linearGradient>
    </defs>
  </svg>
);

export const TestimonialNaviagtionButton = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  padding: 0.5rem;
  background: #2e2c54;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: none;
  transform: scale(1.1) rotate(${({ flip }) => (flip ? 180 : 0)}deg);
  outline: none;
  cursor: pointer;
  overflow: hidden;

  & > svg {
    margin: auto;
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    svg {
      animation: ${arrowAnim} 1s ease;
    }
  }
`;

const TestimonialCard = ({ clientName, message, clientPicture }) => (
  <Container className="testimonial--container" clientPicture={clientPicture}>
    <ExtendedH3>{clientName || "Anonymous"}</ExtendedH3>
    <TagLine style={{ fontStyle: "italic", color: "#99A1BA" }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{
          transform: "scale(-0.8, .8) translateY(6px)",
          marginRight: ".4rem",
        }}
      >
        <path d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z" />
      </svg>
      {message || ""}
    </TagLine>
  </Container>
);
export default TestimonialCard;
