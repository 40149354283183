import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import wave1 from "../assets/img/wave1.svg";
import wave2 from "../assets/img/wave2.svg";
import starSparkles from "../assets/img/ribbons.svg";
import { H2, TagLine } from "../styled/commons";
import { useInView } from "react-intersection-observer";
import TestimonialCard, {
  TestimonialsContainer,
  TestimonialNaviagtionButton,
  TestimonialIcon,
} from "../components/TestimonialCard";

import hndrxx from "../assets/img/testimonials/hndrxx_testimonial_lbdiuj.png";
import josh from "../assets/img/testimonials/josh_testimonial_h98tdm.png";
import lucky from "../assets/img/testimonials/lucky_testimonial_ohsmgx.png";
import victor from "../assets/img/testimonials/victor_testimonial2_nvox6a.png";
import sheriff from "../assets/img/testimonials/sheriff_testimonial_cgqopt.png";

const fadeInOut = keyframes`
    0% {
        opacity: .7;
        transform: translateY(2px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);

    }
`;

const Container = styled.div`
  height: 70vh;
  width: 100%;
  background: #18162f;
  position: relative;
  z-index: 20;
  margin-top: -20vh;
  margin-bottom: 120vh;
  /* transform: translateY(-100vh); */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &::before {
    content: " ";
    position: absolute;
    width: 100%;
    top: -22.8rem;
    left: 0;
    background: url(${wave1}) no-repeat;
    height: 23rem;
    background-size: contain;
    background-position: center bottom;
    z-index: 2;
  }

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    bottom: -22.8rem;
    left: 0;
    background: url(${wave2}) no-repeat;
    height: 23rem;
    background-size: contain;
    background-position: center top;
    z-index: 2;
  }

  div.props--sparkels {
    width: 100%;
    height: 40rem;
    position: absolute;
    top: -4rem;
    left: 0;

    animation-name: ${fadeInOut};
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-out;

    will-change: opacity;

    background: url(${starSparkles}) no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 3;

    @media (max-width: 768px) {
      background-position: top left;

      background-size: 200%;
    }
  }
`;

const Absolute = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
`;

const Section6 = () => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const testimonialSlidesRef = useRef();
  const scrollBy = useRef(400);
  const scrollButtonOffsetBeforeHide = useRef(200);
  const leftNavBtnRef = useRef();
  const rightNavBtnRef = useRef();

  const handleLeftButtonClick = () => {
    smoothHorizontalScrolling(
      testimonialSlidesRef.current,
      200,
      -scrollBy.current,
      testimonialSlidesRef.current.scrollLeft
    );
  };

  const handleRightButtonClick = () => {
    smoothHorizontalScrolling(
      testimonialSlidesRef.current,
      200,
      +scrollBy.current,
      testimonialSlidesRef.current.scrollLeft
    );
  };

  const smoothHorizontalScrolling = (e, time, amount, start) => {
    var eAmt = amount / 100;
    var curTime = 0;
    var scrollCounter = 0;
    while (curTime <= time) {
      window.setTimeout(
        smoothScrollTimeoutHandler,
        curTime,
        e,
        scrollCounter,
        eAmt,
        start
      );
      curTime += time / 100;
      scrollCounter++;
    }
  };

  const smoothScrollTimeoutHandler = (e, sc, eAmt, start) => {
    e.scrollLeft = eAmt * sc + start;
  };

  const testimonialOnScrollHandler = () => {
    if (
      testimonialSlidesRef.current.scrollLeft <=
      scrollButtonOffsetBeforeHide.current
    ) {
      leftNavBtnRef.current.style.visibility = "hidden";
    } else {
      leftNavBtnRef.current.style.visibility = "visible";
    }

    if (
      testimonialSlidesRef.current.offsetWidth +
        testimonialSlidesRef.current.scrollLeft >=
      testimonialSlidesRef.current.scrollWidth -
        scrollButtonOffsetBeforeHide.current
    ) {
      rightNavBtnRef.current.style.visibility = "hidden";
    } else {
      rightNavBtnRef.current.style.visibility = "visible";
    }
  };

  useEffect(() => {
    const elem = testimonialSlidesRef.current;
    elem.addEventListener("scroll", testimonialOnScrollHandler);
    return () => elem.removeEventListener("scroll", testimonialOnScrollHandler);
  }, []);

  return (
    <Container ref={ref}>
      <div className="props--sparkels"></div>
      <Absolute>
        <div
          className={`flex-col animate__animated ${
            inView ? "animate__fadeInUp" : "not-visible"
          }`}
        >
          <H2
            className="h2  stylized--center"
            stylized={true}
            style={{ width: "50%", textAlign: "center" }}
          >
            What Our Users Say About Us{" "}
            <span role="img" aria-label="fingers">
              💬
            </span>
          </H2>
          <TagLine
            style={{
              marginTop: "1.5rem",
              width: "80%",
              textAlign: "center",
              color: "rgba(255, 255, 255, .8)",
            }}
          >
            {/* You can add and manage your favourite right there. */}
            Here are just a few of our wonderful users comments
          </TagLine>
        </div>
        <TestimonialsContainer
          className={`flex-col animate__animated ${
            inView ? "animate__fadeInUp" : "not-visible"
          }`}
        >
          <TestimonialNaviagtionButton
            style={{ visibility: "hidden" }}
            ref={leftNavBtnRef}
            className="testimonial_naviagtion_button--left"
            onClick={handleLeftButtonClick}
          >
            <TestimonialIcon />
          </TestimonialNaviagtionButton>
          <TestimonialNaviagtionButton
            ref={rightNavBtnRef}
            className="testimonial_naviagtion_button--right"
            onClick={handleRightButtonClick}
            flip={true}
          >
            <TestimonialIcon />
          </TestimonialNaviagtionButton>
          <div className="pane" ref={testimonialSlidesRef}>
            <div className="row">
              <div style={{ width: "10vw" }}></div>
              <TestimonialCard
                clientPicture={lucky}
                clientName="Lucky A."
                message="Ever since I started using Switch, it's been awesome"
              />
              <TestimonialCard
                clientPicture={josh}
                clientName="Joshua O."
                message="Switch has saved a lot of stress hopping between windows"
              />
              <TestimonialCard
                clientPicture={hndrxx}
                clientName="Hafeez A."
                message="Productivity is what Switch offers, it does this well"
              />
              <TestimonialCard
                clientPicture={victor}
                clientName="Victor A."
                message="Switch is such a fantastic tool since inception"
              />
              <TestimonialCard
                clientPicture={sheriff}
                clientName="Sheriff S."
                message="Arguably one of the best productivity tool out there"
              />
              <div style={{ width: "10vw" }}></div>
            </div>
          </div>
        </TestimonialsContainer>
      </Absolute>
    </Container>
  );
};

export default Section6;
