import React from "react";
import styled, { keyframes } from "styled-components";
import wave1 from "../assets/img/wave1.svg";
import wave2 from "../assets/img/wave2.svg";
import starSparkles from "../assets/img/star-sparkles1.svg";
import dockGirl from "../assets/img/dock-girl.svg";
import astro from "../assets/img/astro.svg";
import rocket from "../assets/img/rocket.svg";
import { H2, TagLine } from "../styled/commons";
import Flame from "../components/Flame";
import { useInView } from "react-intersection-observer";

const fadeInOut = keyframes`
    0% {
        opacity: .7;
        transform: translateY(2px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);

    }
`;

const Container = styled.div`
  height: 60vh;
  width: 100%;
  background: #18162f;
  position: relative;
  z-index: 20;
  /* margin-top: 10vh; */
  margin-top: 50vh;
  /* transform: translateY(30vh); */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &::before {
    content: " ";
    position: absolute;
    width: 100%;
    top: -22.8rem;
    left: 0;
    background: url(${wave1}) no-repeat;
    height: 23rem;
    background-size: contain;
    background-position: center bottom;
  }

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    bottom: -22.8rem;
    left: 0;
    background: url(${wave2}) no-repeat;
    height: 23rem;
    background-size: contain;
    background-position: center top;
    z-index: 2;
  }

  div.props--sparkels {
    width: 100%;
    height: 40rem;
    position: absolute;
    top: -12.8rem;
    left: 0;

    animation-name: ${fadeInOut};
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-out;

    background: url(${starSparkles}) no-repeat;
    background-size: contain;
    background-position: center;

    z-index: 4;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
  }

  .rocket__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .rocket__group {
    transform: scale(0.8) translate(-3vh, 27vh) rotate(7deg);
  }
`;

const Absolute = styled.div`
  height: 120%;
  width: 78%;

  position: absolute;
  top: 0;
  left: 12.5%;

  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-column-gap: 2rem;
  position: absolute;
  z-index: 3;

  transform: translateY(-10rem);

  .row {
    flex: 1;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 80%;
    left: 10%;

    .row.rocket,
    .astro-img {
      display: none;
    }

    .h2,
    p {
      text-align: center;
      width: 100% !important;
    }

    .dockGirl {
      margin-bottom: 3rem;
    }
  }
`;

const Section2 = () => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <Container ref={ref}>
      <div className="props--sparkels"></div>
      <Absolute>
        <div id="about" className="row">
          <img
            className={`dockGirl animate__animated ${
              inView ? "animate__fadeInUp" : "not-visible"
            }`}
            src={dockGirl}
            alt="Keyboard Combo Centric"
          />

          <div
            className={`animate__animated ${
              inView ? "animate__fadeInUp" : "not-visible"
            }`}
          >
            <H2 className="h2" stylized={true} style={{ width: "80%" }}>
              Use shortcuts to switch apps{" "}
              <span role="img" aria-label="fingers">
                🔁
              </span>
            </H2>
            <TagLine style={{ marginTop: "2rem", width: "85%" }}>
              Switch's keyboard centric interface allows for quick navigation
              between different applications
            </TagLine>
          </div>
        </div>
        <div className="row rocket">
          <div
            style={{ position: "relative" }}
            className={`rocket__group animate__animated ${
              inView ? "animate__zoomInDown" : "not-visible"
            }`}
          >
            <object
              type="image/svg+xml"
              className="rocket__image"
              data={rocket}
              style={{
                height: "100%",
                width: "100%",
                marginTop: "0rem",
              }}
            >
              Rocket
            </object>
            <Flame />
          </div>
        </div>
        <div className="row">
          <div
            className={`animate__animated ${
              inView ? "animate__fadeInUp" : "not-visible"
            }`}
          >
            <H2 className="h2" stylized={true} style={{ width: "75%" }}>
              A handy dock for your fequent apps{" "}
              <span role="img" aria-label="anchor">
                🚀
              </span>
            </H2>
            <TagLine
              style={{ marginBottom: "2rem", marginTop: "2rem", width: "80%" }}
            >
              Maximize productivity, efficiency by quickly and easily organizing
              apps with Switch app
            </TagLine>
          </div>
          <img
            src={astro}
            className={`astro-img animate__animated ${
              inView ? "animate__fadeInBottomRight" : "not-visible"
            }`}
            style={{
              maxHeight: "450px",
              transform: "scale(1.1) translateY(2rem)",
            }}
            alt="Astronaunt"
          />
        </div>
      </Absolute>
    </Container>
  );
};

export default Section2;
