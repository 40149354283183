import React from "react";
import styled from "styled-components";
import wave4 from "../assets/img/wave4.svg";
import attaboy from "../assets/img/attaboy.svg";
import { H2, TagLine } from "../styled/commons";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  height: 77vh;
  background: #0e1817;
  transform: translateY(30vh);
  position: relative;
  z-index: 16;
  margin-bottom: 100vh;

  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    bottom: -22.8rem;
    left: 0;
    background: url(${wave4}) no-repeat;
    height: 23rem;
    background-size: contain;
    background-position: center top;
  }
`;

const Absolute = styled.div`
  height: 120%;
  width: 80%;

  position: absolute;
  top: 30rem;
  left: 11%;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 3rem;
  position: absolute;
  z-index: 3;

  transform: translateY(-10rem);

  .row {
    flex: 1;

    img {
      width: 100%;
      height: auto;
    }

    .rockster__tagline {
      margin-bottom: 2rem;
      margin-top: 2rem;
      width: 80%;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr;
    left: 10%;
    top: 47%;
    width: 80%;

    .h2,
    p {
      text-align: center;
      width: 100% !important;
    }

    .rockster__tagline {
      margin-bottom: 0;
    }

    .rockster__container {
      img {
        width: 100%;
      }
    }
  }
`;

const Section4 = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  return (
    <Container>
      <Absolute ref={ref}>
        <div className="row">
          <div
            className={`animate__animated ${
              inView ? "animate__fadeInUp" : "not-visible"
            }`}
          >
            <H2 stylized={true} style={{ width: "80%" }} className="h2">
              Flow! Key-combo rockster{" "}
              <span role="img" aria-label="flow">
                🤘
              </span>
            </H2>
            <TagLine className="rockster__tagline" style={{ width: "100%" }}>
              No more funny breaks visiting dock/taskbar just to move between
              windows! Flow like a rockster with Switch app
            </TagLine>
          </div>
        </div>
        <div className="row rockster__container animate__animated">
          <img src={attaboy} alt="Attay boy" />
        </div>
      </Absolute>
    </Container>
  );
};

export default Section4;
