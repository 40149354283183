import React from "react";
import styled from "styled-components";
import { HeaderItem } from "../styled/Header";

const Container = styled.section`
  padding-top: 0rem;
  transform: translateY(20rem);

  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

const FootList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  margin: 0 auto;
  width: 75%;

  .last-item {
    display: none;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .last-item {
      display: block;
    }

    li {
      padding: 5px 10px;
    }
  }
`;

const FootItem = styled.li`
  font-size: 0.9rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;

const FootLink = styled(HeaderItem)`
  display: inline;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const SocialList = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem auto 1.5rem auto;

  li:first-child {
    margin-right: 1.5rem;
  }

  li:last-child {
    margin-left: 1.5rem;
  }
`;

const FootTagLine = styled.div`
  text-align: center;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.25);
  padding-bottom: 2rem;
  text-transform: uppercase;
  cursor: default;

  @media (max-width: 768px) {
    padding-bottom: 3rem;
  }
`;

const FootIcon = styled.a`
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
`;

const Foot = () => {
  return (
    <Container role="footer">
      <FootList>
        <FootItem>
          <FootLink
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            href="#download"
          >
            <span>Download</span>
          </FootLink>
        </FootItem>
        <FootItem>
          <FootLink href="https://purchase.get-switch.app">
            <span>Buy Now</span>
          </FootLink>
        </FootItem>

        <FootItem>
          <FootLink href="mailto:victor.olorunbunmi@gmail.com">
            <span>Contact</span>
          </FootLink>
        </FootItem>

        <FootItem>
          <FootLink href="https://discord.com/invite/uXqdSM24qH">
            <span>Join Discord</span>
          </FootLink>
        </FootItem>

        <FootItem>
          <FootLink href="https://github.com/ahkohd/switch-desktop/wiki/FAQs">
            <span>FAQs</span>
          </FootLink>
        </FootItem>
        <FootItem>
          <FootLink href="https://github.com/ahkohd/switch-desktop/issues">
            <span>Report A Bug</span>
          </FootLink>
        </FootItem>
        <FootItem className="last-item">
          <FootLink>
            <span>Github</span>
          </FootLink>
        </FootItem>
      </FootList>
      <SocialList>
        <FootItem>
          <FootIcon
            href="mailto:victor.olorunbunmi@gmail.com"
            target="_blank"
            role="nav-link"
          >
            <span role="img" aria-label="email">
              📭
            </span>
          </FootIcon>
        </FootItem>
        <FootItem>
          <FootIcon
            href="https://twitter.com/GetSwitchHQ"
            target="_blank"
            role="nav-link"
          >
            <span role="img" aria-label="twitter">
              {" "}
              🐦
            </span>
          </FootIcon>
        </FootItem>
        <FootItem>
          <FootIcon
            href="https://github.com/ahkohd/switch-desktop"
            target="_blank"
            role="nav-link"
          >
            <span role="img" aria-label="github">
              🐙
            </span>
          </FootIcon>
        </FootItem>
      </SocialList>
      <FootTagLine>
        Copyright &copy;{new Date().getFullYear()}{" "}
        <a href="https://victor-aremu.web.app">
          <span role="img" aria-label="emoji">
            🔗
          </span>
          Victor Aremu
        </a>
        {". "}All rights reserved
      </FootTagLine>
    </Container>
  );
};

export default Foot;
