import React, { useRef } from "react";

import styled from "styled-components";

const Container = styled.div`
  width: 18rem;
  height: 5rem;

  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 99999;

  border-radius: 1.25rem;
  padding: 1.8rem 2rem;

  cursor: default;

  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2); */

  background: #17181c;
  background: #08081b;
  background: #111111;

  color: #98a2ba;
`;

const H5 = styled.h5`
  color: white;
  font-family: "Space Mono";
  font-weight: 600;
  font-size: 0.95rem;
  text-transform: uppercase;

  margin: 0 0 0.5rem 0;
`;

const P = styled.p`
  margin: 0;
  font-size: 0.95em;
`;

const CloseButton = styled.button`
  width: 2rem;
  height: 2rem;

  color: white;
  background: rgba(255, 255, 255, 0.1);

  border-radius: 50%;
  border: none;

  display: flex;

  position: absolute;
  right: 1rem;
  top: 1rem;

  cursor: pointer;

  & > svg {
    margin: auto;
    transform: scale(0.95);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const CookieCard = () => {
  const cardRef = useRef();

  const handleClose = () => {
    localStorage.setItem("accept_cookie", JSON.stringify(true));
    cardRef.current.classList.remove("animate__bounceInRight");
    cardRef.current.classList.add("animate__bounceOutRight");

    if (window.drift) {
      setTimeout(() => {
        window.drift.api.widget.show();
      }, 300);
    }
  };

  return (
    <Container
      className="cookie-card animate__animated animate__bounceInRight"
      ref={cardRef}
    >
      <CloseButton onClick={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </CloseButton>
      <H5>
        <span role="img" aria-label="cookie">
          🍪
        </span>{" "}
        Cookie notice
      </H5>
      <P>Our website uses cookies to provide you the best experience.</P>
    </Container>
  );
};

export default CookieCard;

export const hasAcceptedCookiePersmission = () =>
  JSON.parse(localStorage.getItem("accept_cookie"));
