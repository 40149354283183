import React from "react";
import styled from "styled-components";
import { H2, H3, TagLine, gradientValues } from "../styled/commons";
import { useInView } from "react-intersection-observer";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import starSparkles from "../assets/img/star-sparkles1.svg";
import macosDemo from "../assets/img/demo/macos.gif";
import windowsDemo from "../assets/img/demo/win10_v7wuil.gif";

const Container = styled.div`
  width: 100%;
  background: #111;
  display: flex;
  margin-top: -110vh;
  padding-top: 38vh;
  padding-bottom: 65vh;
  z-index: 14;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  & > div {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    padding-top: 25vh;
    padding-bottom: 43vh;
  }
  .cross-platform-section__pane {
    @media (max-width: 768px) {
      h1,
      p {
        width: 90% !important;
      }
      .compare-silder-container {
        margin: 1rem 1.5rem !important;
      }
    }
  }
`;

const SliderHandle = styled.div`
  width: 1.1rem;
  height: 100%;
  border-radius: 200px;
  background: #c3bff1;
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(1.1rem * 2);
    height: calc(1.1rem * 2);
    /* background: #d25674; */
    background: ${gradientValues};
    border-radius: 50%;
    top: calc(50% - 1.1rem);
    left: calc(50% - 1.1rem);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:hover::before {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

const Section5 = () => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <Container>
      <div
        className="cross-platform-section__pane"
        ref={ref}
        style={{ background: `url(${starSparkles}) no-repeat` }}
      >
        <H2
          stylized={false}
          style={{ width: "35%", textAlign: "center", margin: 0 }}
          className={`h2 stylized--center flex-col animate__animated ${
            inView ? "animate__fadeInUp" : "not-visible"
          }`}
        >
          A Cross-platform window manager{" "}
          <span role="img" aria-label="emote">
            🧜‍♂️
          </span>
        </H2>
        <TagLine
        id="how-it-works"
          className={`h2 stylized--center flex-col animate__animated ${
            inView ? "animate__fadeInUp" : "not-visible"
          }`}
          style={{
            width: "40%",
            textAlign: "center",
            marginTop: "2.5rem",
          }}
        >
          Available to both MacOS and Windows 10 users. Have a glance at Switch
          in action on these platforms
        </TagLine>

        <div
          className="compare-silder-container flex-col animate__animated"
          style={{
            marginTop: "4rem",
            overflow: "hidden",
            borderRadius: "15px",
          }}
        >
          <ReactCompareSlider
            handle={<SliderHandle />}
            position={50}
            itemOne={
              <ReactCompareSliderImage src={macosDemo} alt="macOs demo" />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={windowsDemo}
                alt="Windows 10 demo"
              />
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "70%",
            margin: "2rem auto 0 auto",
          }}
        >
          <H3>
            MacOS{" "}
            <span role="img" aria-label="mac os">
              🍏
            </span>
          </H3>{" "}
          <H3>
            Windows 10{" "}
            <span role="img" aria-label="windows 10">
              🏠
            </span>
          </H3>
        </div>
      </div>
    </Container>
  );
};

export default Section5;
